import axios from 'axios';

//Defino el entorno si es producción o desarrollo
class Api {
  baseURL;
  baseURLPython;

  constructor() {
    //produccion
    this.baseURLPython = 'https://5003.skynetsas.net/api/v1';
    //staging
    //this.baseURLPython = 'http://127.0.0.1:8000/api/v1';
  }

  ///////////////////////////////////////////////////////////
  // STAR USER SERVICES PYTHON
  //////////////////////////////////////////////////////////

  // -------------------------------- Login ------------------------------
  login = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/login/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getEntregas = async () => {
    const urlrequest = `${this.baseURLPython}/generales/get-entregas/`;
    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getEntrega = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/get-entrega/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  createEntrega = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/create-entrega/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  getVisitasByEntrega = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/get-visitas-entrega/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getVisitas = async () => {
    const urlrequest = `${this.baseURLPython}/generales/get-visitas/`;
    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  editVisita = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/edit-visita/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  // -------------------------------- Propietarios ------------------------------
  // getPropietarios = async () => {
  //   const urlrequest = `${this.baseURLPython}/get-propietarios/`;
  //   const result = await axios
  //     .get(urlrequest)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  //   return result;
  // };
  getPropietario = async (data) => {
    try {
      const url = `${this.baseURLPython}/propietarios/get-propietario/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getPropietarios = async () => {
    try {
      const url = `${this.baseURLPython}/propietarios/get-propietarios/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getPropietariosReady = async () => {
    try {
      const url = `${this.baseURLPython}/propietarios/get-propietarios-ready/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getPropietariosVehiculos = async () => {
    try {
      const url = `${this.baseURLPython}/propietarios/get-propietarios-vehiculos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  createPropietario = async (data) => {
    try {
      const url = `${this.baseURLPython}/propietarios/create-propietario/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  editPropietario = async (data) => {
    try {
      const url = `${this.baseURLPython}/propietarios/edit-propietario/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  getPropietarioByDcto = async (data) => {
    try {
      const url = `${this.baseURLPython}/propietarios/get-propietario-dcto/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  // -------------------------------- Conductores ------------------------------
  getConductores = async () => {
    try {
      const url = `${this.baseURLPython}/conductores/get-conductores/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getConductoresOnlyName = async () => {
    try {
      const url = `${this.baseURLPython}/conductores/get-conductores-only-name/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getConductoresReady = async () => {
    try {
      const url = `${this.baseURLPython}/conductores/get-conductores-ready/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getConductor = async (data) => {
    try {
      const url = `${this.baseURLPython}/conductores/get-conductor/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getConductorByDcto = async (data) => {
    try {
      const url = `${this.baseURLPython}/conductores/get-conductor-dcto/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  createConductor = async (data) => {
    try {
      const url = `${this.baseURLPython}/conductores/create-conductor/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  editConductor = async (data) => {
    try {
      const url = `${this.baseURLPython}/conductores/edit-conductor/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  // createConductor = async (data) => {
  //   const url = `${this.baseURLPython}/conductores/create-conductor/`;
  //   const headerOptions = {
  //     'Content-Type': 'multipart/form-data',
  //   };
  //   const result = await axios
  //     .post(url, data.body, { headers: headerOptions })
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  //   return result;
  // };

  // -------------------------------- Auxiliares ------------------------------
  getAuxiliares = async () => {
    try {
      const url = `${this.baseURLPython}/auxiliares/get-auxiliares/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getAuxiliaresOnlyName = async () => {
    try {
      const url = `${this.baseURLPython}/auxiliares/get-auxiliares-only-name/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getAuxiliaresReady = async () => {
    try {
      const url = `${this.baseURLPython}/auxiliares/get-auxiliares-ready/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getAuxiliar = async (data) => {
    try {
      const url = `${this.baseURLPython}/auxiliares/get-auxiliar/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  createAuxiliar = async (data) => {
    try {
      const url = `${this.baseURLPython}/auxiliares/create-auxiliar/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  editAuxiliar = async (data) => {
    try {
      const url = `${this.baseURLPython}/auxiliares/edit-auxiliar/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  // -------------------------------- Vehiculos ------------------------------
  getVehiculos = async () => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-vehiculos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getVehiculosOnlyPlaca = async () => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-vehiculos-only-placa/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getVehiculo = async (data) => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-vehiculo/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getVehiculoByPlaca = async (data) => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-vehiculo-placa/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getCountVehiculos = async () => {
    try {
      const url = `${this.baseURLPython}/vehiculos/count-vehiculos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getMarcasVehiculos = async () => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-marcas-vehiculos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getLineasVehiculos = async () => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-lineas-vehiculos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getTiposCarrocerias = async () => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-tipos-carrocerias/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getConfiguracion = async () => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-configuracion/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getAseguradoras = async () => {
    try {
      const url = `${this.baseURLPython}/vehiculos/get-aseguradoras/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  createVehiculo = async (data) => {
    try {
      const url = `${this.baseURLPython}/vehiculos/create-vehiculo/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  editVehiculo = async (data) => {
    try {
      const url = `${this.baseURLPython}/vehiculos/edit-vehiculo/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  // -------------------------------- Generales - Datos Pesonales ------------------------------
  getCiudades = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-ciudades/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getDataRemesasManifiestos = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-data-remesas-manifiestos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getTotalTipoRemesasManifiestos = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-total-tipo-remesa-manifiesto/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getSucursales = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-sucursales/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getRutas = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-rutas/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getGrupos = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-grupos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getTiposGrupos = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-tipos-grupos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getGeneradores = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-generadores/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getSedesGenerador = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/get-sedes-generador/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getUsuarioByDcto = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/get-usuario-dcto/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getAseguradorasCarga = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-aseguradoras-carga/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getRemesa = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/get-remesa/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getRemesas = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-remesas/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getRemesasList = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-remesas-list/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  informeRemesas = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/informe-remesas/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getManifiesto = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/get-manifiesto/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getManifiestos = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-manifiestos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  informeManifiestos = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/informe-manifiestos/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getManifiestosOnlyConsecutivo = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-manifiestos-only-consecutivo/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getRemesasManifiestos = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-remesas-manifiestos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getConsecutivosRemesas = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-consecutivos-remesas/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getLastIDRemesas = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-lastid-remesas/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getLastIDManifiestos = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-lastid-manifiestos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  anularRemesa = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/anular-remesa/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  anularRemesaNacional = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/anular-remesa-nacional/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  anularCumplidoRemesaNacional = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/anular-cumplido-remesa-nacional/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  activeUsuario = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/active-usuario/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  activeVehiculo = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/active-vehiculo/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  blockUsuario = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/block-usuario/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  blockVehiculo = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/block-vehiculo/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  createGenerador = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/create-generador/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  editSede = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/edit-sede/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  createRemesa = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/create-remesa/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  createRemesaDefault = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/create-remesa-default/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  createRemesaNacional = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/create-remesa-nacional/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  cumplirRemesaNacional = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/cumplir-remesa-nacional/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  createManifiesto = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/create-manifiesto/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  cumplirManifiesto = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/cumplir-manifiesto/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  cumplirManifiestoNacional = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/cumplir-manifiesto-nacional/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  createManifiestoNacional = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/create-manifiesto-nacional/`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };
      const result = await axios.post(url, data.body, { headers: headerOptions });
      return result;
    } catch (err) {
      throw err;
    }
  };

  anularManifiesto = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/anular-manifiesto/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  anularManifiestoNacional = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/anular-manifiesto-nacional/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  anularCumplidoManifiesto = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/anular-cumplido-manifiesto/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  anularCumplidoManifiestoNacional = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/anular-cumplido-manifiesto-nacional/`;
      const result = await axios.post(url, data.body);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getEmpaques = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-empaques/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getCapitulos = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-capitulos/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getPartidas = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-partidas/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getFactorIca = async () => {
    try {
      const url = `${this.baseURLPython}/generales/get-factor-ica/`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getGeneradorByDcto = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/get-generador-dcto/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };

  getTitular = async (data) => {
    try {
      const url = `${this.baseURLPython}/generales/get-usuario/${data.id}`;
      const result = await axios.get(url);
      return result;
    } catch (err) {
      throw err;
    }
  };
}
export default Api;
