<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav ref="menu" id="sidebar">
      <div class="shadow-bottom"></div>
      <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
        <!-- Dashboard   -->
        <li v-if="user.idRol < 3" class="menu">
          <router-link to="/" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-home"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              <span>{{ $t('Dashboard') }}</span>
            </div>
          </router-link>
        </li>
        <!-- Entregas   -->
        <li v-if="user.idRol === 8" class="menu">
          <router-link to="/operaciones/lista-de-entregas" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-layers"
              >
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                <polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline>
              </svg>
              <span>{{ $t('Entregas') }}</span>
            </div>
          </router-link>
        </li>
        <!-- Control Flota   -->
        <li v-if="user.idRol < 5" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#controlflota" aria-controls="controlflota" aria-expanded="false">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check-square"
              >
                <polyline points="9 11 12 14 22 4"></polyline>
                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
              </svg>
              <span>Control Flota</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>
          <ul id="controlflota" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li v-if="[0, 5].includes(store.getters.user.idArea)">
              <router-link to="/control-flota/dashboard" @click="toggleMobileMenu">Dashboard</router-link>
            </li>
            <li>
              <router-link to="/control-flota/lista-de-propietarios" @click="toggleMobileMenu">Propietarios</router-link>
            </li>
            <li>
              <router-link to="/control-flota/lista-de-conductores" @click="toggleMobileMenu">Conductores</router-link>
            </li>
            <li>
              <router-link to="/control-flota/lista-de-auxiliares" @click="toggleMobileMenu">Auxiliares</router-link>
            </li>
            <li>
              <router-link to="/control-flota/lista-de-vehiculos" @click="toggleMobileMenu">Vehículos</router-link>
            </li>
          </ul>
        </li>
        <!-- Operaciones   -->
        <li v-if="user.idRol < 5" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#operaciones" aria-controls="operaciones" aria-expanded="false">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-truck"
              >
                <rect x="1" y="3" width="15" height="13"></rect>
                <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                <circle cx="5.5" cy="18.5" r="2.5"></circle>
                <circle cx="18.5" cy="18.5" r="2.5"></circle>
              </svg>
              <span>{{ $t('Operaciones') }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>
          <ul id="operaciones" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li v-if="[0, 3].includes(user.idArea)">
              <router-link to="/operaciones/dashboard" @click="toggleMobileMenu">Dashboard</router-link>
            </li>
            <li v-if="[0, 3].includes(user.idArea)">
              <router-link to="/operaciones/lista-de-entregas" @click="toggleMobileMenu">Entregas</router-link>
            </li>
            <li v-if="[0, 3].includes(user.idArea)">
              <router-link to="/operaciones/lista-de-visitas" @click="toggleMobileMenu">Visitas</router-link>
            </li>
            <li>
              <router-link to="/operaciones/lista-de-remesas" @click="toggleMobileMenu">Remesas</router-link>
            </li>
            <li>
              <router-link to="/operaciones/lista-de-manifiestos" @click="toggleMobileMenu">Manifiestos</router-link>
            </li>
            <!--<li>
              <router-link to="" @click="toggleMobileMenu">No Entregadas</router-link>
            </li> -->
          </ul>
        </li>
        <!-- Mantenimiento   -->
        <li v-if="[0, 6].includes(user.idArea)" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#mantenimiento" aria-controls="mantenimiento" aria-expanded="false">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-tool"
              >
                <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
              </svg>
              <span>Mantenimiento</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="mantenimiento" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li v-if="[0, 6].includes(user.idArea)">
              <router-link to="/mantenimiento/dashboard" @click="toggleMobileMenu">Dashboard</router-link>
            </li>
            <li>
              <router-link to="/comercial/lista-de-generadores" @click="toggleMobileMenu">Mtto Preventivo</router-link>
            </li>
            <li>
              <router-link to="/comercial/lista-de-generadores" @click="toggleMobileMenu">Mtto Correctivo</router-link>
            </li>
          </ul>
        </li>
        <!-- Comercial   -->
        <li v-if="[0, 7].includes(user.idArea) || user.id === 10" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#comercial" aria-controls="manifiestos" aria-expanded="false">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-package"
              >
                <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                <line x1="12" y1="22.08" x2="12" y2="12"></line>
              </svg>
              <span>Comercial</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="comercial" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link to="/comercial/lista-de-generadores" @click="toggleMobileMenu">Generadores Carga</router-link>
            </li>
          </ul>
        </li>
        <!-- Informes   -->
        <li class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#informes" aria-controls="manifiestos" aria-expanded="false">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file-text"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
              <span>Informes</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>
          <ul id="informes" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link to="/informes/lista-de-manifiestos" @click="toggleMobileMenu">Lista de Manifiestos</router-link>
            </li>
            <li>
              <router-link to="/informes/lista-de-remesas" @click="toggleMobileMenu">Lista de Remesas</router-link>
            </li>
          </ul>
        </li>
        <!-- Generales   -->
        <!-- <li v-if="user.idRol < 3" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#generales" aria-controls="generales" aria-expanded="false">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-airplay"
              >
                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                <polygon points="12 15 17 21 7 21 12 15"></polygon>
              </svg>
              <span>Generales</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>
        </li> -->
      </perfect-scrollbar>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();
  const user = store.getters.user;
  const menu_collapse = ref('dashboard');

  onMounted(() => {
    const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
    if (selector) {
      const ul = selector.closest('ul.collapse');
      if (ul) {
        let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      } else {
        selector.click();
      }
    }
  });

  const toggleMobileMenu = () => {
    if (window.innerWidth < 991) {
      store.commit('toggleSideBar', !store.state.is_show_sidebar);
    }
  };
</script>
