import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/index.vue'),
    meta: { requiresAuth: true, allowedRoles: [1, 2] },
  },
  {
    path: '/googleMap',
    name: 'googleMap',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/map/map.vue'),
  },
  {
    path: '/control-flota/dashboard',
    name: 'controlFlotaDashboard',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/index.vue'),
  },
  {
    path: '/control-flota/lista-de-conductores',
    name: 'controlFlotaListaConductores',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/listaConductores.vue'),
  },
  {
    path: '/control-flota/lista-de-auxiliares',
    name: 'controlFlotaListaAuxiliares',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/listaAuxiliares.vue'),
  },
  {
    path: '/control-flota/lista-de-vehiculos',
    name: 'controlFlotaListaVehiculos',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/listaVehiculos.vue'),
  },
  {
    path: '/control-flota/crear-vehiculo',
    name: 'controlFlotaCrearVehiculo',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/crearVehiculo.vue'),
  },
  {
    path: '/control-flota/editar-vehiculo/:id',
    name: 'controlFlotaEditarVehiculo',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/editarVehiculo.vue'),
  },
  {
    path: '/control-flota/crear-conductor',
    name: 'controlFlotaCrearConductor',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/crearConductor.vue'),
  },
  {
    path: '/control-flota/editar-conductor/:id',
    name: 'controlFlotaEditarConductor',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/editarConductor.vue'),
  },
  {
    path: '/control-flota/crear-auxiliar',
    name: 'controlFlotaCrearAuxiliar',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/crearAuxiliar.vue'),
  },
  {
    path: '/control-flota/editar-auxiliar/:id',
    name: 'controlFlotaEditarAuxiliar',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/editarAuxiliar.vue'),
  },
  {
    path: '/control-flota/lista-de-propietarios',
    name: 'controlFlotaListaPropietarios',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/listaPropietarios.vue'),
  },
  {
    path: '/control-flota/crear-propietario',
    name: 'controlFlotaCrearPropietario',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/crearPropietario.vue'),
  },
  {
    path: '/control-flota/editar-propietario/:id',
    name: 'controlFlotaEditarPropietario',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/control_flota/editarPropietario.vue'),
  },
  {
    path: '/comercial/lista-de-generadores',
    name: 'comercialListaGeneradores',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/comercial/listaGeneradores.vue'),
  },
  {
    path: '/comercial/lista-sedes-generador/:id',
    name: 'comercialListaSedesGenerador',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/comercial/listaSedesGenerador.vue'),
  },
  {
    path: '/comercial/crear-generador',
    name: 'comercialCrearGenerador',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/comercial/crearGenerador.vue'),
  },
  {
    path: '/comercial/editar-generador/:id',
    name: 'comercialEditarGenerador',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/comercial/editarGenerador.vue'),
  },
  {
    path: '/operaciones/dashboard',
    name: 'operacionesDashboard',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/index.vue'),
  },
  {
    path: '/operaciones/lista-de-entregas',
    name: 'operacionesListaEntregas',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/listaEntregas.vue'),
  },
  {
    path: '/operaciones/entrega/:id',
    name: 'operacionesEntrega',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/visitasEntrega.vue'),
  },
  {
    path: '/operaciones/lista-de-visitas',
    name: 'operacionesListaVisitas',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/listaVisitas.vue'),
  },
  {
    path: '/operaciones/lista-de-remesas',
    name: 'operacionesListaRemesas',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/listaRemesas.vue'),
  },
  {
    path: '/operaciones/crear-remesa',
    name: 'operacionesCrearRemesa',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/crearRemesa.vue'),
  },
  {
    path: '/operaciones/cumplir-remesa/:id',
    name: 'operacionesCumplirRemesa',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/cumplirRemesa.vue'),
  },
  {
    path: '/operaciones/lista-de-manifiestos',
    name: 'operacionesListaManifiestos',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/listaManifiestos.vue'),
  },
  {
    path: '/operaciones/crear-manifiesto',
    name: 'operacionesCrearManifiesto',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/crearManifiesto.vue'),
  },
  {
    path: '/operaciones/cumplir-manifiesto/:id',
    name: 'operacionesCumplirManifiesto',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/operaciones/cumplirManifiesto.vue'),
  },
  {
    path: '/informes/lista-de-remesas',
    name: 'informeListaRemesas',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/informes/informeRemesas.vue'),
  },
  {
    path: '/informes/lista-de-manifiestos',
    name: 'informeListaManifiestos',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/informes/informeManifiestos.vue'),
    // meta: { requiresAuth: true, allowedRoles: [1, 2] },
  },
  {
    path: '/mantenimiento/dashboard',
    name: 'mantenimientoDashboard',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/mantenimiento/index.vue'),
  },
  {
    path: '/pages/error404',
    name: 'error404',
    component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/error404.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/unauthorized.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error4042',
    component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/error404.vue'),
    meta: { layout: 'auth' },
  },
  //Auth
  {
    path: '/log_out',
    name: 'log_out',
    component: () => import('../views/logOut/nextLogIn.vue'),
  },
  {
    path: '/auth/logOut',
    name: 'logOut',
    component: () => import('@/views/logOut/logOut.vue'),
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth-login-boxed" */ '../views/auth/login.vue'),
    meta: { layout: 'auth' },
  },
];

// const router = new createRouter({
const router = createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const isLogged = sessionStorage.getItem('is_logged');
  const userRole = store.state.user?.idRol;

  // Si la ruta requiere autenticación y el usuario no está autenticado
  if (to.meta.requiresAuth && !isLogged) {
    return next('/auth/login');
  }

  // Si la ruta tiene roles restringidos y el usuario no tiene permiso
  if (to.meta.allowedRoles && !to.meta.allowedRoles.includes(userRole)) {
    return next('/pages/unauthorized');
  }

  // Establece el layout según la ruta
  if (to.meta.layout && to.meta.layout === 'auth') {
    store.commit('setLayout', 'auth');
  } else {
    store.commit('setLayout', 'app');
  }

  // Permite la navegación si pasa todas las verificaciones
  next();
});

export default router;
